<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select :items="sites" label="Select Site" @change="siteSelected" outlined></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="studyMetaData"
        :items-per-page="10"
        class="elevation-1"
        style="min-width:1200px"
      >
      <template v-slot:[`item.submit_date`]="{ item }">
        {{ formatDate(item.submit_date) }}
      </template>
      <template v-slot:[`item.update_data_date`]="{ item }">
        {{ formatDate(item.update_data_date) }}
      </template>
      <template v-slot:[`item.update_data_user`]="{ item }">
        {{ item.update_data_user == 'none' ? '' : item.update_data_user }}
      </template>
      <template v-slot:[`item.include_study`]="{ item }">
        {{ item.include_study ? 'Yes' : '' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="primary" @click="downloadFile(item.filename)"><v-icon>mdi-download</v-icon></v-btn>
      </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { Storage } from "aws-amplify";

export default {
  name: "Overview",
  components: {},
  data() {
    return {
      selectedSite: "",
      tableHeaders: [
          { text: 'Filename', value: 'filename' },
          { text: 'Submitted By', value: 'submit_user' },
          { text: 'Submitted Date', value: 'submit_date' },
          { text: 'Updated By', value: 'update_data_user' },
          { text: 'Updated Date', value: 'update_data_date' },
          { text: 'Include', value: 'include_study' },
          { text: 'Download', value: 'actions', sortable: false },
        ],
    };
  },
  methods: {
    siteSelected(siteName) {
      this.selectedSite = siteName;
      this.$store.dispatch("getStudyMetadataForSite", siteName);
    },
    downloadFile(filename) {
      Storage.get(this.selectedSite + "/" + filename, { download: true }).then(
        (result) => this.downloadBlob(result.Body, filename)
      );
    },
    downloadBlob(blob, filename) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "download";
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);
      a.click();
      return a;
    },
    formatDate (value) {
      if (!value) return '';
      if( value === 0 ) return '';
      return new Date(value).toLocaleString("en-US");
    },
  },
  computed: {
    sites() {
      return this.$store.getters.sites;
    },
    studyMetaData() {
      return this.$store.getters.studyMetadataForSite;
    },
  },
  watch: {},
  created() {
    this.$store.dispatch("querySites");
  },
  mounted() {
    // if (this.submitHistory && this.submitHistory.length > 0) {
    //   this.createHistograms();
    // }
  },
};
</script>

<style>
.trendbox {
  margin: 16px 0 0 32px; /*  top  right  bottom  left   */
  width: 560px;
  display: inline-block;
}
</style>
