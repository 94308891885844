var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.sites,"label":"Select Site","outlined":""},on:{"change":_vm.siteSelected}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"min-width":"1200px"},attrs:{"headers":_vm.tableHeaders,"items":_vm.studyMetaData,"items-per-page":10},scopedSlots:_vm._u([{key:"item.submit_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.submit_date))+" ")]}},{key:"item.update_data_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.update_data_date))+" ")]}},{key:"item.update_data_user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.update_data_user == 'none' ? '' : item.update_data_user)+" ")]}},{key:"item.include_study",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.include_study ? 'Yes' : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadFile(item.filename)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }